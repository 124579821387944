var breakW = 1024;	//基準となるブレークポイント
var iOSviewportW = 0;
var ua = navigator.userAgent.toLowerCase();
var isiOS = (ua.indexOf("iphone") > -1) || (ua.indexOf("ipod") > -1) || (ua.indexOf("ipad") > -1);
if(isiOS){
  iOSviewportW = $(window).width();
}
var w = $(window).width();
function updateMetaViewport(){
  console.log(w);
  var viewportContent;
  if(isiOS){
    w = iOSviewportW;
  }
  if (w < breakW && w > 480 ){
    console.log(w)
    viewportContent = "width=" + breakW + ",initial-scale=1";
  }else{
    console.log("dddd")
    viewportContent = "width=device-width,initial-scale=1";
  }
  console.log(viewportContent)
  $("meta[name='viewport']").attr("content", viewportContent);
}


window.addEventListener("resize", updateMetaViewport, false);
window.addEventListener("orientationchange", updateMetaViewport, false);
//初回イベント強制発動
var ev = document.createEvent("UIEvent");
ev.initEvent("resize", true, true)
window.dispatchEvent(ev);
