$(function () {
  $(window).on('load', function () {
    animate();
  });
});
function animate() {
  window.sr = ScrollReveal({});
  sr.reveal('.chapter', { origin: 'bottom', distance: '10px', duration: 200, scale: 1.0, delay: 350, opacity: 0, easing: "cubic-bezier(0.785, 0.135, 0.15, 0.86)" });
  sr.reveal('.p-index-releases__contents', { origin: 'bottom', distance: '10px', duration: 500, scale: 1.0, delay: 500, opacity: 0, easing: "cubic-bezier(0.785, 0.135, 0.15, 0.86)" });
  sr.reveal('.p-index-recruit-section > figure', { origin: 'bottom', distance: '10px', duration: 500, scale: 1.0, delay: 500, opacity: 0, easing: "cubic-bezier(0.785, 0.135, 0.15, 0.86)" });
  sr.reveal('.p-index-recruit-section__text', { origin: 'bottom', distance: '10px', duration: 500, scale: 1.0, delay: 650, opacity: 0, easing: "cubic-bezier(0.785, 0.135, 0.15, 0.86)" });
  sr.reveal('.p-index-hero__message > h2', { origin: 'bottom', distance: '10px', duration: 500, scale: 1.0, delay: 650, opacity: 0, easing: "cubic-bezier(0.785, 0.135, 0.15, 0.86)" });
  sr.reveal('.p-index-hero__message > h3', { origin: 'bottom', distance: '10px', duration: 500, scale: 1.0, delay: 800, opacity: 0, easing: "cubic-bezier(0.785, 0.135, 0.15, 0.86)" });
  sr.reveal('.p-index-hero__message > p', { origin: 'bottom', distance: '10px', duration: 500, scale: 1.0, delay: 1000, opacity: 0, easing: "cubic-bezier(0.785, 0.135, 0.15, 0.86)" });
  sr.reveal('.p-index-services-section:nth-child(1)', { origin: 'bottom', distance: '10px', duration: 500, scale: 1.0, delay: 500, opacity: 0, easing: "cubic-bezier(0.785, 0.135, 0.15, 0.86)" });
  sr.reveal('.p-index-services-section:nth-child(2)', { origin: 'bottom', distance: '10px', duration: 500, scale: 1.0, delay: 650, opacity: 0, easing: "cubic-bezier(0.785, 0.135, 0.15, 0.86)" });
  sr.reveal('.p-index-services-section:nth-child(3)', { origin: 'bottom', distance: '10px', duration: 500, scale: 1.0, delay: 800, opacity: 0, easing: "cubic-bezier(0.785, 0.135, 0.15, 0.86)" });
  sr.reveal('.p-index-services-section:nth-child(4)', { origin: 'bottom', distance: '10px', duration: 500, scale: 1.0, delay: 800, opacity: 0, easing: "cubic-bezier(0.785, 0.135, 0.15, 0.86)" });
  sr.reveal('.cta', { origin: 'bottom', distance: '10px', duration: 500, scale: 1.0, delay: 500, opacity: 0, easing: "cubic-bezier(0.785, 0.135, 0.15, 0.86)" });

  sr.reveal('.p-service-section > figure', { origin: 'bottom', distance: '20px', duration: 500, scale: 1.0, delay: 500, opacity: 0, easing: "cubic-bezier(0.785, 0.135, 0.15, 0.86)" });
  sr.reveal('.p-service-section__text', { origin: 'bottom', distance: '20px', duration: 500, scale: 1.0, delay: 650, opacity: 0, easing: "cubic-bezier(0.785, 0.135, 0.15, 0.86)" });
};
