$(function () {
  console.log("ojfoejofjeo")
  $('.p-index-hero').vegas({
    slides: [
      { src: '/wp/wp-content/themes/tohsen/images/hero-image.webp' },
      { src: '/wp/wp-content/themes/tohsen/images/hero-image-02.webp' },
      { src: '/wp/wp-content/themes/tohsen/images/hero-image.webp' }
    ],
    transition: 'fade',
    transitionDuration: 2000,
    delay: 8000,
    timer: false
  });
});