$(document).on('click', '#return', function () {
  $('body,html').animate({ scrollTop: 0 }, 500, 'swing');
  var form_name = $(this).attr('name')
  $("." + form_name).hide().html(initial).fadeIn("slow");
  Object.keys(values).forEach(function (key) {
    $("." + form_name + ' #' + key).val(this[key]);
  }, values);
});


$(document).on('click', '#confirm', function (element) {
  var form_name = $(this).attr('name')
  console.log(form_name)
  validateInputs(form_name);
  text = "";
  initial = $("." + form_name).html();
  $('body,html').animate({ scrollTop: 0 }, 500, 'swing');
  console.log(initial);
  confirmInputs(form_name);
  $("." + form_name).hide().html(text).fadeIn("slow");
});

function validateInputs(form_name) {
  console.log("validateInputs", form_name);
  var notEntered = [];
  var required = '';
  if (form_name == 'company-form') {
    required = {
      name: '氏名',
      kana: 'カナ',
      email: 'メール',
      tel: '電話番号',
      body: 'お問い合わせ内容'
    };
  } else if (form_name == 'career-form') {
    required = {
      name: '氏名',
      kana: 'カナ',
      email: 'メール',
      tel: '電話番号',
      birthday: '生年月日',
      post: '郵便番号',
      address: '住所',
      body: '志望動機'
    };
  }
  // var required = {};
  Object.keys(required).forEach(function (key) {
    var name = this[key];
    console.log(name);
    if (key == 'checkboxes') {
      var array = [];
      $('.' + form_name + ' input[type="checkbox"]:checked').each(function () {
        array.push($(this).val());
      })
      $('.' + form_name + ' #' + key).parent().parent().find("span").empty();
      if (array.length == 0) {
        $('.' + form_name + ' #' + key).parent().parent().find("span").append(name + "を入力ください");
        notEntered.push(key)
      }
    } else if (key == 'select' || key == 'status') {
      var value = $('.' + form_name + ' #' + key + ' option:selected').val();
      $('.' + form_name + ' #' + key).css('border', '')
      $('.' + form_name + ' #' + key).next("span").empty();
      if (!value) {
        $('.' + form_name + ' #' + key).next("span").append(name + "を入力ください");
        $('.' + form_name + ' #' + key).css('border', '1px solid #d23f40')
        notEntered.push(key)
      }
    } else if (key == 'email') {
      $('.' + form_name + ' #' + key).next("span").empty();
      $('.' + form_name + ' #' + key).css('border', '')
      var value = $('.' + form_name + ' #' + key).val();
      var reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
      if (!reg.test(value)) {
        $('.' + form_name + ' #' + key).next("span").text(name + "を正しく入力ください");
        $('.' + form_name + ' #' + key).css('border', '1px solid #d23f40')
        notEntered.push(key)
      }
    } else if (key == 'tel') {
      $('.' + form_name + ' #' + key).next("span").empty();
      $('.' + form_name + ' #' + key).css('border', '')
      var value = $('.' + form_name + ' #' + key).val();
      var reg = /^[0-9\-]+$/;
      if (!reg.test(value)) {
        $('.' + form_name + ' #' + key).next("span").text(name + "を正しく入力ください");
        $('.' + form_name + ' #' + key).css('border', '1px solid #d23f40')
        notEntered.push(key)
      }
    } else {
      $('.' + form_name + ' #' + key).next("span").empty();
      $('.' + form_name + ' #' + key).css('border', '')
      var value = $('.' + form_name + ' #' + key).val();
      if (!value) {
        $('.' + form_name + ' #' + key).next("span").text(name + "を入力ください");
        $('.' + form_name + ' #' + key).css('border', '1px solid #d23f40')
        notEntered.push(key)
      }
    }
  }, required)
  if (notEntered.length > 0) {
    exit;
  }
}

function confirmInputs(form_name) {
  values = {}
  checkboxes = []
  text += '<div class="section__table">' + '\n';
  var inputs = '';
  if (form_name == 'company-form') {
    inputs = {
      name: '氏名',
      kana: 'カナ',
      email: 'メール',
      tel: '電話番号',
      body: 'お問い合わせ内容'
    };
  } else if (form_name == 'career-form') {
    inputs = {
      name: '氏名',
      kana: 'カナ',
      email: 'メール',
      tel: '電話番号',
      birthday: '生年月日',
      post: '郵便番号',
      address: '住所',
      body: '志望動機'
    };
  }
  Object.keys(inputs).forEach(function (key) {
    var name = this[key];
    text += '<dl>' + '\n';
    if (key == 'checkboxes') {
      var checked = "";
      $('.' + form_name + ' :checkbox:checked').each(function () {
        //値を取得
        checked += $(this).val() + ' ';
        checkboxes.push($(this).val());
      });
      text += '<dd>' + name + '</dd>' + '\n';
      text += '<dt>' + checked + '</dt>' + '\n';
      values[key] = checkboxes;
    } else if (key == 'purpose' || key == 'time') {
      var value = $('.' + form_name + ' #' + key + ' option:selected').val();
      values[key] = value;
      text += '<dd>' + name + '</dd>' + '\n';
      text += '<dt>' + value + '</dt>' + '\n';
    } else {
      var value = $('.' + form_name + ' #' + key).val();
      values[key] = value;
      text += '<dd>' + name + '</dd>' + '\n';
      text += '<dt>' + value + '</dt>' + '\n';
    }
    text += '</dl>' + '\n';

  }, inputs);
  text += '</div>' + '\n';
  text += '<div class="button__wrapper my-30">' + '\n';
  text += '<a class="button --return mr-30" id="return" type="button" name="' + form_name + '" value="">戻る</a>'
  text += '<a class="button" id="submit" name="' + form_name + '">送信する</a>'
  text += '</div>' + '\n';
}
