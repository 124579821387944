$(document).on('click', '#submit', function () {
  var form_name = $(this).attr('name');
  var data = setData(form_name);
  $.ajax({
    url: '../wp-admin/admin-ajax.php',
    type: 'POST',
    data: data
  }).done(function (data) {
    var message = "";
    message += '<div class="sub-heading__wrapper">' + '\n';
    message += '<h2 class="sub-heading">お問い合わせを受け付けました。</h3>' + '\n';
    message += '</div>' + '\n';
    message += '<div class="p-single-content">' + '\n';
    message += '<p>' + '\n';
    message += 'お問い合わせいただきました内容を確認後、担当者より改めてご連絡いたします。' + '\n';
    message += '</p>' + '\n';
    message += '</div>' + '\n';
    $("." + form_name).hide().html(message).fadeIn("slow");
  }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
    var message = "";
    message += '<div class="sub-heading__wrapper">' + '\n';
    message += '<h2 class="sub-heading">メッセージの送信に失敗しました。</h3>' + '\n';
    message += '</div>' + '\n';
    message += '<div class="p-single-content">' + '\n';
    message += '<p>' + '\n';
    message += '恐れ入りますが、時間をおいてもう一度お試しください。' + '\n';
    message += '</p>' + '\n';
    message += '</div>' + '\n';
    $("." + form_name).hide().html(message).fadeIn("slow");
    console.log(errorThrown.message);
  })
  $('body,html').animate({ scrollTop: 0 }, 500, 'swing');
})

function setData(form_name) {
  var data = {};
  if (form_name == 'company-form') {
    data = {
      'action': 'contacts_form',
      'フォーム': 'お問い合わせ',
      '氏名': values.name,
      'カナ': values.kana,
      'メール': values.email,
      '電話番号': values.tel,
      'お問い合わせ内容': values.body
    }
    console.log(data);
  } else if (form_name == 'career-form') {
    data = {
      'action': 'contacts_form',
      'フォーム': '採用について',
      '氏名': values.name,
      'カナ': values.kana,
      'メール': values.email,
      '電話番号': values.tel,
      '生年月日': values.birthday,
      '郵便番号': values.post,
      '住所': values.address,
      '志望動機': values.body
    }
    console.log(data);
  }
  return data;
}
