$(function() {
  var navHeight = $('.l-header').outerHeight();
  var fixedClass = '--fixed';

  $(window).on('load scroll', function() {
    var value = $(this).scrollTop(),
        scrollPos = $(window).height() + value;
    if ( value > 50 ) {
      $('.menu').addClass(fixedClass);
      $('.l-header').addClass(fixedClass);
    } else if (value < 20) {
      $('.menu').removeClass(fixedClass);
      $('.l-header').removeClass(fixedClass);
    }
  });
});
